@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "sfpro";
  src: url("../public/fonts/SFpro.ttf");
}
body{
  font-family: "sfpro" !important;
}

.lh-1 {
  line-height: 1 !important;
}
.lh-1_1 {
  line-height: 1.1 !important;
}
.lh-1_2 {
  line-height: 1.2 !important;
}
.lh-1_3 {
  line-height: 1.3 !important;
}
.lh-1_4 {
  line-height: 1.4 !important;
}
.lh-1_5 {
  line-height: 1.5 !important;
}
.lh-1_6 {
  line-height: 1.6 !important;
}
.lh-1_7 {
  line-height: 1.7 !important;
}
.lh-1_8 {
  line-height: 1.8 !important;
}
.lh-1_9 {
  line-height: 1.9 !important;
}

.font-sfpro{
  font-family: "sfpro";
}

.container-wrapper {
  @apply max-w-[1280px] w-[88%] sm:w-[90%] mx-auto;
}

.top-bg {
  background: var(
    --secondary,
    linear-gradient(136deg, #00aef3 2.61%, #008ef9 51.51%, #0076fd 96.58%)
  );
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset;

  padding: 16px 75.001px 14px 70px;
  align-items: center;
  width: 100%;
}

.card-p {
  border-radius: 18.268px;
  background: #fff;
  width: 300px;
  box-shadow: 0px 3.218px 3.218px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 18.268px 18.268px 27.402px 18.268px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14.614px;
  margin: auto;
}

.box-s{
  box-shadow: 0px 3.218px 3.218px 0px rgba(0, 0, 0, 0.25);

 
}
.hero-bg{
  background-image: url('../public/images/slider.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
}

.br-d{
  border-radius: 1px 1px 6px 6px;
}

.hero-bg2{
  background-image: url('../public/images/slider2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
}


.hero-bg3{
  background-image: url('../public/images/slider3.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
}
.hero-bg4{
  background-image: url('../public/images/slider4.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 700px;
}
.btn-main{
  border-radius: 4px;
background: var(--secondary, linear-gradient(136deg, #00AEF3 2.61%, #008EF9 51.51%, #0076FD 96.58%));
}

.menu p{
  font-weight: 700;
  
}

.menu p:hover{
  color:#008EF9 ;
  
}
.css-zjq1i3{
  margin: auto;
}

.card__three::before, .card__three::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform 0.3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  pointer-events: none;
}
.card__three::before {
  transform-origin: left top;
}
.card__three::after {
  transform-origin: right bottom;
}
.card__three:hover::before, .card__three:hover::after, .card__three:focus::before, .card__three:focus::after {
  transform: scale3d(1, 1, 1);
}

.swiper-wrapper{
  padding-bottom: 10px !important;
  width: fit-content;
}

.bg-gall{
  background-image: url('../public/images/gall.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}
.react-photo-gallery--gallery img{
  border: 1px solid #a7a7a7;
  border-radius: 6px;
  padding: 2px;
  width: 340px;
  height: auto;
  margin-bottom: 14px !important;

}

.react-photo-gallery--gallery div{
justify-content: center;

}

.swiper-pagination-bullet{
  width: 15px !important;
  height: 15px !important;
  background-color: #fff !important;
}